.__content_module_image img {
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

.__content_module_image img {
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

.w-richtext-figure-type-image img {
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

.w-richtext-figure-type-image figcaption {
  text-align: center;
}

.__content_module_underline {
  border-bottom: 1px solid #14213d;
}

.__content_module_pros-cons {
  display: -ms-grid;
  display: grid;
  padding: 1em 1.5em;
  grid-auto-columns: 1fr;
  grid-column-gap: 2em;
  grid-row-gap: 2em;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border: 1px solid #50596d;
}

.__content_module_pros-cons .pros-cons__block ul {
  list-style: none;
  padding-left: 0px;
}

.__content_module_pros-cons .pros-cons__block ul.pros li::before {
  content: '';
  background-image: url(https://assets.website-files.com/628dacb25a4c396071d2a9ef/62e49bfe16c2b10087053617_icon__thumbsup.svg);
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  width: 1em;
  height: 1em;
  position: relative;
  top: 0.1rem;
  margin-right: 0.5rem;
}

.__content_module_pros-cons .pros-cons__block ul.cons li::before {
  content: '';
  background-image: url(https://assets.website-files.com/628dacb25a4c396071d2a9ef/62e49bff86728d8bb1e8b684_icon__thumbsdown.svg);
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  width: 1em;
  height: 1em;
  position: relative;
  top: 0.1rem;
  margin-right: 0.5rem;
}

.__content_module_pros-cons .pros-cons__block ul li {
  margin-bottom: 1em;
}

.__content_module_btn_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

a.__content_module_btn {
  display: flex;
  width: 100%;
  margin-top: 33px;
  margin-bottom: 33px;
  padding: 0.8rem 1rem;
  justify-content: center;
  align-items: center;
  border-bottom-style: none;
  border-radius: 0.2rem;
  background-color: hsla(1, 100%, 50%, 1);
  transition: background-color 0.2s;
  color: white;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  text-decoration: none !important;
  max-width: 250px;
}

ul.__content_module_no_bullets {
  list-style: none;
}

img.__content_module_review__additional__img {
  width: 30%;
  margin-bottom: 1em;
  border-radius: 50%;
}

.__content_module_center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.__content_module_two_column {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.__content_module_comment {
  padding-left: 20px;
  border-left: 4px solid orange;
  font-size: 20px;
  margin-bottom: 30px;
}
